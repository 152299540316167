<template>
  <div class="personalCenter mainContainer">
    <el-button type="text" @click="backUp()"><i class="icon icon-back mr10"></i>返回</el-button>
    <div class="contDiv">
      <el-form :model="inform" label-width="100px" size="small">
        <el-form-item label="头像:">
          <!-- <img :src="inform.headPicImg" class="img"> -->
          <el-image style="width: 88px; height: 116px" :src="inform.headPicImg" fit="cover" :preview-src-list="[inform.headPicImg]"></el-image>
        </el-form-item>
        <el-form-item label="账号:">{{ inform.account }}</el-form-item>
        <el-form-item label="姓名:">{{ inform.personName }}</el-form-item>
        <el-form-item label="籍贯:">{{ inform.nativePlace }}</el-form-item>
        <el-form-item label="企业:">{{ inform.depaName }}</el-form-item>
        <el-form-item label="角色:">{{ inform.roleName }}</el-form-item>
        <!-- <el-form-item label="职务:">{{ inform.postName }}</el-form-item> -->
        <el-form-item label="登录IP:">{{ inform.lastLoginIp }}</el-form-item>
        <el-form-item label="个人手机:">{{ inform.phone }}</el-form-item>
        
      </el-form>
    </div>
  </div>
</template>
<script>
import { apiUrl,globalStr } from '@/assets/js/api';
import qs from 'qs'
var vm;
export default {
  name:'personalCenter',
  data() {
    return {
      inform:{
        headPicImg:'',//头像
        account:'',//账号
        personName:'',//姓名
        nativePlace:'',//籍贯
        depaName:'',//企业
        roleName:'',//角色
        postName:'',//职务
        lastLoginIp:'',//登录IP
        phone:'',//个人手机
      },
    }
  },
  created(){
    vm = this
    vm.userGetDetail()
  },
  methods:{
    //返回上一页
    backUp(){
      this.$router.go(-1);
    },
    //获取详情
    async userGetDetail(){
      const res = await apiUrl.userGetDetail()
      if(res?.code==200){
        let roleArr = res.data.roles?.map(value=>{
          return value.roleName;
        })
        let postArr = res.data.personVo?.posts?.map(value=>{
          return value.postName;
        })
        vm.inform.headPicImg = `${globalStr}/fileView${res.data.personVo?.headPicInfo?.fileRelativePath}`
        vm.inform.account = res.data.account
        vm.inform.nativePlace = res.data.personVo?.nativePlace
        vm.inform.depaName = res.data.depaName//企业
        vm.inform.roleName = roleArr?.join(',')//角色
        vm.inform.postName = postArr?.join('、')//职务
        vm.inform.personName= res.data.personVo ? res.data.personVo.personName:'-';//姓名
        vm.inform.lastLoginIp = res.data.lastLoginIp
        vm.inform.phone = res.data.personVo?.phone
      }else{
        vm.$message.error(res.message)
      }
    },
  }
}
</script>
<style lang="scss">
.personalCenter{
  .contDiv{
    padding: 50px 0 0 100px;
    color:#333;
    .img{
      width: 88px;
      height: 116px;
      display: block;
    }
  }
}
</style>